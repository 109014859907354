// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kuchyne-nabytok-na-mieru-js": () => import("./../src/pages/kuchyne-nabytok-na-mieru.js" /* webpackChunkName: "component---src-pages-kuchyne-nabytok-na-mieru-js" */),
  "component---src-pages-o-studiu-js": () => import("./../src/pages/o-studiu.js" /* webpackChunkName: "component---src-pages-o-studiu-js" */),
  "component---src-pages-servis-oprava-kuchynskych-liniek-js": () => import("./../src/pages/servis-oprava-kuchynskych-liniek.js" /* webpackChunkName: "component---src-pages-servis-oprava-kuchynskych-liniek-js" */)
}

